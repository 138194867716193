import React, { useState } from 'react';



const FooterTwoFunnel = ({langUS=false}) => {

    return (
        <div>
           
                <div className="footer-style-2 bg_image bg_image--1" data-black-overlay="6">

           <div className="wrapper plr--50 plr_sm--20" style={{paddingBottom: window.type_device ? "5px": "1px" }} >
           
               <div className="row align-items-center justify-content-between">
                   
                   <div className="col-12" style={{marginBottom: window.type_device ? "20px": "1px" }}>
                       <div className="inner text-center mt_sm--20">
                           <div className="text-center">
                               <p>Copyright © 2024 DojoFullStack -  {!langUS ? "Todos los derechos reservados.": "All rights reserved."} </p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>

        </div>
        
       
    )
}
export default FooterTwoFunnel;
